<script setup lang="ts">
import { formatDocument } from '../../../../helpers'
import { getCommissionValue, getBondedValues } from '../comissionHelper'
import FragmentInfo from './_fragment-info.vue'
import CommissionInfo from './_commission-info.vue'
import SearchButton from '../../../../components/search-button.vue'
import TopNotice from './_top-notice.vue'

definePage({
  meta: {
    title: 'Visualizar pedido',
    icon: 'eye'
  },
  props: true
})

const props = defineProps<{
  id: string
  section?: string
}>()

const route = useRoute()
const orderStore = useStore('order')
const hasNotice = computed(() =>
  orderStore.item.is_reshipment
  || orderStore.item.was_assembled
  || orderStore.item.assembled_orders?.length
  || orderStore.item.error
)

// Picks all the commission fields descriptions to show as titles in the table
const commissionProps: Record<keyof NonNullable<typeof orderStore.item.commissions>[0], string> =
  Object.entries(orderStore.description.properties.commissions.items.properties)
    .reduce((acc: any, [key, value]: any) => (acc[key] = value.description, acc), {})

const update = async () => {
  if (!props.id) {
    return
  }

  await orderStore.$actions.get({
    filters: {
      _id: orderStore.item._id || props.id
    }
  })
  orderStore.$actions.pushToHistory()
}

onMounted(update)
watch(() => props.id, update)

onUnmounted(() => {
  orderStore.$actions.clearItem()
})
</script>

<template>
  <div v-if="orderStore.item._id" class="
    tw-flex
    tw-flex-col
    tw-gap-6
  ">
    <!-- <div class="tw-mb-4"> -->
    <!--   <div>Status:</div> -->
    <!--   <div -->
    <!--     v-clickable -->
    <!--     class=" -->
    <!--       tw-text-xl -->
    <!--       tw-font-semibold -->
    <!--     " -->
    <!--     @click="orderStore.copyToClipboard" -->
    <!--   > -->
    <!--     {{ t(orderStore.item.status || '') }} -->
    <!--   </div> -->
    <!-- </div> -->
    <div v-if="hasNotice" :key="orderStore.item._id" class="
        tw-flex
        tw-flex-col
        tw-gap-y-4
    ">
      <top-notice v-if="orderStore.item.is_reshipment">
        <div>Este pedido é um reenvio feito por {{ orderStore.item.reshipment_user?.name || "Desconhecido" }}</div>
      </top-notice>

      <top-notice v-if="orderStore.item.was_assembled">
        Este pedido foi marcado para ser despachado juntamente com outro pedido
        com o mesmo endereço de entrega.
      </top-notice>

      <top-notice v-if="orderStore.item.assembled_orders?.length">
        Este pedido foi marcado para ser despachado juntamente com esses pedidos:
        <div class="
          tw-flex
          tw-gap-x-2
        ">
          <div v-for="order in orderStore.item.assembled_orders" :key="order._id">
            {{ order.token }}
          </div>
        </div>
      </top-notice>

      <top-notice v-if="orderStore.item.error">
        <div>Pedido com erro:</div>
        <div>{{ orderStore.item.error }}</div>
      </top-notice>
    </div>

    <div class="
      tw-flex
      tw-flex-col
      tw-gap-4
      tw-p-8
      tw-rounded-xl
      aeria-surface-alt
    ">
      <div>
        <div class="
            tw-text-[14pt]
            tw-font-[600]
          ">
          <fragment-info :text="orderStore.item.receiver_name || orderStore.item.customer.name"></fragment-info>
        </div>
        <div class="tw-text-[12pt]">
          <fragment-info :text="formatDocument(orderStore.item.customer?.document as string)"></fragment-info>
        </div>
      </div>

      <div class="
          tw-flex
          tw-flex-col
          tw-gap-1
        ">
        <aeria-icon small icon="phone">
          <fragment-info :text="orderStore.item.customer?.phone"></fragment-info>
        </aeria-icon>
        <aeria-icon small icon="envelope">
          <fragment-info :text="orderStore.item.customer?.email"></fragment-info>
        </aeria-icon>
      </div>
    </div>
    <aeria-panel v-if="orderStore.item._id && (
      route.query.section === 'summary'
      || !route.query.section
    )
    " outer-header fill>
      <info-box class="
        tw-grid
        lg:tw-grid-cols-2
        tw-gap-x-20
      ">
        <div class="
          tw-flex
          tw-flex-col
          tw-gap-y-5
        ">
          <fragment-info label="Status">
            <status-badge :color="orderStore.$actions.getComputedColor(orderStore.item.status)">
              {{ t(orderStore.item.status || '-') }}
            </status-badge>
          </fragment-info>
          <fragment-info label="Criado em" :text="formatDateTime(orderStore.item.date_created, true)"></fragment-info>
          <fragment-info label="Atualizado em"
            :text="formatDateTime(orderStore.item.date_updated, true)"></fragment-info>
          <fragment-info label="Capturado em" :text="formatDateTime(orderStore.item.created_at, true)"></fragment-info>
          <fragment-info label="Integração" :text="orderStore.item.integration?.formatted_name">
            <template v-slot:after>
              <search-button :item="orderStore.item.integration"
                :onclick="() => orderStore.$actions.viewIntegration(orderStore.item)"></search-button>
            </template>
          </fragment-info>
          <fragment-info label="Plataforma" :text="orderStore.item.integration?.platform"></fragment-info>
          <fragment-info label="Token da transação" :text="orderStore.item.token"></fragment-info>
          <fragment-info :label="orderStore.description.properties.retained_amount.description" :text="getCommissionValue({
            value: orderStore.item.retained_amount,
            type: 'amount',
            order: orderStore.item,
            isRetained: true
          }).value"></fragment-info>
          <fragment-info :label="orderStore.description.properties.retained_percentage.description" :text="getCommissionValue({
            value: orderStore.item.retained_percentage,
            type: 'percentage',
            order: orderStore.item,
            isRetained: true
          }).value"></fragment-info>
        </div>

        <div class="
          tw-flex
          tw-flex-col
          tw-gap-y-5
        ">
          <fragment-info label="Transportadora" :text="orderStore.item.shipping_provider"></fragment-info>
          <fragment-info label="Serviço de entrega"
            :text="orderStore.item.shipping_service?.description"></fragment-info>
          <fragment-info label="Rastreio" :text="orderStore.item.tracking_code"></fragment-info>
          <fragment-info label="Remessa (id local)" :text="orderStore.item.shipment?.local_id"></fragment-info>
          <fragment-info label="Remessa (id estrangeira)" :text="orderStore.item.shipment?.foreign_id"></fragment-info>
          <fragment-info label="Nome do cliente" :text="orderStore.item.receiver_name || orderStore.item.customer.name">
            <template v-slot:after>
              <search-button :item="orderStore.item.customer"
                :onclick="() => orderStore.$actions.viewCustomer(orderStore.item)"></search-button>
            </template>
          </fragment-info>
          <fragment-info label="Endereço de entrega"
            :text="orderStore.item.shipping_address?.address_line"></fragment-info>
        </div>
      </info-box>
    </aeria-panel>

    <aeria-table v-if="route.query.section === 'summary' || !route.query.section">
      <template #thead>
        <tr>
          <th>Produto</th>
          <th>Plano</th>
          <th>Quantidade</th>
          <th>Valor</th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="(plan, index) in orderStore.item.plans" :key="`plan-${index}`">
          <td>
            <fragment-info :text="plan.product?.name"></fragment-info>
          </td>
          <td>
            <fragment-info :text="plan.name"></fragment-info>
          </td>
          <td>
            <fragment-info :text="plan.quantity?.toString()"></fragment-info>
          </td>
          <td>
            <fragment-info :text="`R$ ${plan.price || '-'}`"></fragment-info>
          </td>
        </tr>
      </template>
      <template #tfoot>
        <tr>
          <td colspan="4" class="tw-p-4">
            <fragment-info :text="`Total: R$ ${orderStore.item.amount?.toFixed(2) || 0}`"></fragment-info>
          </td>
        </tr>
      </template>
    </aeria-table>

    <aeria-panel v-if="route.query.section === 'history'" outer-header fill>
      <aeria-table>
        <template #thead>
          <tr>
            <th>Data</th>
            <th>Evento</th>
            <th>Detalhes</th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="(event, index) in orderStore.item.events" :key="`event-${index}`">
            <td>{{ formatDateTime(event.when, true) || '-' }}</td>
            <td>{{ t(event.type) }}</td>
            <td>{{ event.detail }}</td>
          </tr>
        </template>
      </aeria-table>
    </aeria-panel>

    <aeria-panel v-if="route.query.section === 'commissions'" outer-header fill>
      <aeria-table>
        <template #thead>
          <tr>
            <th>{{ commissionProps.type }}</th>
            <th>{{ commissionProps.name }}</th>
            <th>{{ commissionProps.email }}</th>
            <th>{{ commissionProps.document }}</th>
            <th>{{ commissionProps.value }}</th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="(commission, index) in orderStore.item.commissions" :key="`commission-${index}`">
            <td>
              <commission-info :text="commission.type" />
            </td>
            <td>
              <commission-info :text="commission.name" />
            </td>
            <td>
              <commission-info :text="commission.email" />
            </td>
            <td>
              <commission-info :text="commission.document" />
            </td>
            <td>
              <commission-info
                :text="getBondedValues({ value: { amount: commission.value, percentage: commission.percentage } }).value" />
            </td>
          </tr>
        </template>
      </aeria-table>
    </aeria-panel>

    <aeria-panel v-if="route.query.section === 'geolocation'" outer-header fill>
      <info-box class="
        tw-grid
        tw-gap-x-20
        lg:tw-flex
        lg:tw-flex-row
      ">
        <div class="
          tw-flex
          tw-flex-col
          tw-gap-y-5
          lg:tw-min-w-[23rem]
        ">
          <fragment-info label="Cidade" :text="orderStore.item.shipping_address?.city"></fragment-info>
          <fragment-info label="UF" :text="orderStore.item.shipping_address?.state"></fragment-info>
          <fragment-info label="Código postal (CEP)" :text="orderStore.item.shipping_address?.zipcode"></fragment-info>
          <fragment-info label="Bairro" :text="orderStore.item.shipping_address?.district"></fragment-info>
          <fragment-info label="Logradouro" :text="orderStore.item.shipping_address?.address"></fragment-info>
          <fragment-info label="Número" :text="orderStore.item.shipping_address?.number"></fragment-info>
          <fragment-info label="Complemento" :text="orderStore.item.shipping_address?.complement"></fragment-info>
        </div>

        <div class="
          tw-flex-1
          tw-border
        ">
          <div class="gmap_canvas">
            <iframe
              :src="`https://maps.google.com/maps?q=${orderStore.item.shipping_address?.city}+${orderStore.item.shipping_address?.state}+${orderStore.item.shipping_address?.address}+${orderStore.item.shipping_address?.number}&t=&z=18&ie=UTF8&iwloc=&output=embed`"
              width="100%" height="500" id="gmap_canvas" frameborder="0" scrolling="no" marginheight="0"
              marginwidth="0"></iframe>
          </div>
        </div>
      </info-box>
    </aeria-panel>
  </div>
</template>
